import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { getAdNetworksTypesAction } from '@/bus/adNetworksTypes/actions'

type IAdNetworksType = {
  adnetwork: string
  type: string
  value?: number
}
export const useAdNetworksTypes = () => {
  const dispatch = useDispatch()

  const [progress, setProgress] = useState(false)
  const [finished, setFinished] = useState(false)
  const [failed, setFailed] = useState(false)
  const [data, setData] = useState<null | IAdNetworksType[]>(null)

  const getData = () =>
    new Promise((resolve, reject) => {
      setProgress(true)
      setFinished(false)
      setFailed(false)

      const action = getAdNetworksTypesAction({ meta: { resolve, reject } })

      dispatch(action)
    })
      .then((types: any) => {
        setData(
          types?.sort(({ type }: { type: string }) =>
            type === 'yandexdirect' ? -1 : 1
          ) ?? []
        )
        setFinished(true)
      })
      .catch(errors => {
        setFinished(true)
        setFailed(true)
      })
      .finally(() => {
        setProgress(false)
      })

  getData.progress = progress
  getData.finished = finished
  getData.failed = failed

  return {
    types: data,
    setValues: setData,
    getTypes: getData
  }
}
