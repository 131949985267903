import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { calculateAwardsAction } from '@/bus/calculateAwards/actions'

export const useCalculateAwards = () => {
  const dispatch = useDispatch()

  const [progress, setProgress] = useState(false)
  const [finished, setFinished] = useState(false)
  const [failed, setFailed] = useState(false)
  const [data, setData] = useState<null | {
    summary: { budget_amount: number; award_amount: number }
  }>(null)

  const calculate = (props: any) =>
    new Promise((resolve, reject) => {
      setProgress(true)
      setFinished(false)
      setFailed(false)

      const action = calculateAwardsAction({
        ...props,
        meta: { resolve, reject }
      })
      dispatch(action)
    })
      .then((response: any) => {
        setData(response)
        setFinished(true)
      })
      .catch(errors => {
        setFinished(true)
        setFailed(true)
      })
      .finally(() => {
        setProgress(false)
      })

  calculate.progress = progress
  calculate.finished = finished
  calculate.failed = failed

  return {
    awards: data,
    setAwards: setData,
    calculate
  }
}
